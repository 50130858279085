import * as React from 'react';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: pagina no encontrada" />
    <h1>404: Página no encontrada</h1>
    <p>La página a la que intentó ingresar no existe...</p>
  </Layout>
);

export default NotFoundPage;
